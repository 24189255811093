export default {
  "general.account.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
  "general.countries.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
  "general.countries.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])},
  "general.languages.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
  "general.languages.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
  "general.languages.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans"])},
  "registered.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor uw registratie"])},
  "registered.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze event managers zullen binnenkort contact met je opnemen met verdere instructies & mogelijkheden"])},
  "cta.registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk je vrijwilligers overzicht pagina"])},
  "forms.motivations.heading.volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke motivatie heb je om te vrijwilligen?"])},
  "forms.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet alle verstrekte informatie is volledig of geldig, gelieve te controleren"])},
  "forms.areas.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welke regio wil je actief zijn als vrijwilliger?"])},
  "forms.location.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal en locatie"])},
  "forms.location.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen land of taak ingevuld"])},
  "registration.individual.tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individu"])},
  "registration.group.tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
  "registration.group.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwoordelijke"])},
  "registration.group.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie nieuwe vrijwilliger"])},
  "registration.group.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren als"])},
  "forms.remarks.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijf jezelf hier"])},
  "general.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "general.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "forms.size.small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 - 10"])},
  "forms.size.medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 - 40"])},
  "forms.size.large": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41+ of meer"])},
  "forms.size.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen keuze gekozen"])},
  "forms.size.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveel vrijwilligers kan je met jouw club/organisatie engageren?"])},
  "general.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van je club/organisatie"])},
  "general.extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere motivatie"])},
  "forms.extra.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen keuze gekozen"])},
  "forms.club.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen naam ingevuld"])},
  "general.firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "general.lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "forms.birthdate.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
  "forms.birthdate.errors.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen geboortedatum ingevuld"])},
  "forms.birthdate.errors.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, je kunt pas inschrijven vanaf het jaar dat je 16 jaar wordt"])},
  "general.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail adres"])},
  "forms.email.error.unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is al een Letsmove.com account verbonden aan dit e-mail adres. Log in met je bestaand account en vul het formulier in."])},
  "forms.email.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres is een verplicht veld"])},
  "cta.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen op je Letsmove.com account"])},
  "forms.email.error.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen geldig e-mail adres ingevuld"])},
  "forms.locales.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welke regio is je club/organisatie actief?"])},
  "forms.locales.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een postcode op in de buurt waar je club/organisatie wilt vrijwilligen."])},
  "forms.motivations.heading.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is de motivatie van jouw club/organisatie om te vrijwilligen?"])},
  "forms.motivations.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruis alles aan wat van toepassing is"])},
  "forms.motivations.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een centje bijverdienen"])},
  "forms.motivations.fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor het plezier, om deel uit te maken van het event"])},
  "forms.motivations.social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor het sociale aspect"])},
  "forms.motivations.learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om nieuwe ervaringen of vaardigheden op te doen"])},
  "forms.motivations.extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere motivatie"])},
  "forms.postcode.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen geldige postcode ingevuld"])},
  "general.postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "forms.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
  "forms.tel.error.numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dit veld kan je alleen getallen gebruiken"])},
  "forms.tel.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiele telefoon is een verplicht veld"])},
  "general.tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiele telefoon"])},
  "forms.remarks.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere opmerkingen"])},
  "forms.responsible.me.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer mijzelf als verantwoordelijke van een groep"])},
  "forms.responsible.me.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als verantwoordelijke van een club ontvang je alle communicatie van Golazo omtrent het evenement."])},
  "forms.responsible.other.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer iemand anders als verantwoordelijke persoon"])},
  "forms.responsible.other.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat de verantwoordelijke de groep registreren via dit formulier. De verantwoordelijke krijgt een persoonlijk account om de groepsregistratie te beheren."])},
  "forms.responsible.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een keuze"])},
  "forms.privacy.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik verklaar kennis te hebben genomen van de"])},
  "forms.privacy.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy verklaring."])},
  "forms.pre-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze informatie is afkomstig van je Letsmove.com account. Toevoegingen worden automatisch naar je Letsmove.com account geschreven"])},
  "general.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "forms.password.panel.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een wachtwoord"])},
  "forms.password.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggesties"])},
  "forms.password.requirement-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal één kleine letter"])},
  "forms.password.requirement-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens één hoofdletter"])},
  "forms.password.requirement-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten minste één cijfer"])},
  "forms.password.requirement-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal 8 tekens"])},
  "forms.password.error.required.unused-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord moet minstens 8 tekens lang zijn en minstens 1 letter en 1 cijfer bevatten"])},
  "forms.password.error.weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een eerder zwak wachtwoord, maar we zullen het accepteren."])},
  "forms.password.error.strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oké, sterk wachtwoord!"])},
  "general.days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
  "general.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maand"])},
  "general.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])},
  "forms.terms.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de algemene voorwaarden*"])},
  "forms.terms.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met"])},
  "forms.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je krijgt een individuele Letsmove.com account waarmee je jouw vrijwilligersprofiel kunt beheren, zowel voor groepen als individuen."])},
  "cta.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
  "logout.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopelijk tot ziens"])},
  "logout.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bent succesvol uitgelogd"])},
  "logout.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar Golazo"])},
  "logout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "cta.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar Golazo"])},
  "login.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "login.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email om verder te gaan"])},
  "login.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar de vrijwilligersregistratie"])},
  "login.form.email.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig e-mail adres"])},
  "login.form.email.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat je het jouw persoonlijke (e-mailadres?) is "])},
  "login.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga verder"])},
  "login.form.footer.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of"])},
  "login.form.footer.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heb je een vraag of hulp nodig?"])},
  "login.form.footer.privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacybeleid en voorwaarden*"])},
  "login.form.footer.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account aanmaken"])},
  "cta.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])},
  "overview.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijwilligersoverzicht"])},
  "overview.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijwilligers"])},
  "cta.register.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer een nieuwe club of organisatie"])},
  "cta.register.volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer jezelf als vrijwilliger"])},
  "overview.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
  "faq.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faq"])},
  "faq.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht van alle praktische vragen die je als vrijwilliger zou kunnen stellen."])},
  "cta.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar de FAQ"])},
  "management.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer je vrijwilligersregistratie"])},
  "management.description.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ik wil mezelf van het platform verwijderen"])},
  "management.description.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ik wil deze vrijwilligersregistratie verwijderen"])},
  "cta.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig dat je dit wilt verwijderen"])},
  "toast.manage.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesvol verwijderd"])},
  "forms.tasks.heading.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welke soort taak/taken is jouw organisatie geïnteresseerd?"])},
  "forms.tasks.heading.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In welke soort taak/taken ben jij geïnteresseerd?"])},
  "forms.tasks.steward.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seingevers & stewards"])},
  "forms.tasks.steward.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwoordelijk & betrouwbaar, veiligheid voorop en oplettend, assertief en coöperatief"])},
  "forms.tasks.service.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Team"])},
  "forms.tasks.service.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleemoplossend, een manusje-van-alles en een teamspeler"])},
  "forms.tasks.welcome.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom & info team"])},
  "forms.tasks.welcome.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegankelijk, warm en oplossingsgericht, ondersteunend en creatief, meertalig Green Team"])},
  "forms.tasks.green.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green team"])},
  "forms.tasks.green.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands-on op een milieubewuste manier, toekomst- en kwaliteitsgericht, duurzaam en sociaal geïnteresseerd"])},
  "countries.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
  "countries.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])},
  "forms.motivations.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen keuze gekozen"])},
  "forms.tasks.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen keuze gekozen"])},
  "forms.firstname.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen voornaam ingevuld"])},
  "forms.lastname.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen familienaam ingevuld"])},
  "forms.general.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan."])},
  "forms.password.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen wachtwoord ingevuld"])},
  "forms.volunteer.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen andere motivatie gespecifieerd"])}
}